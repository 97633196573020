<template>
  <div>
    <navi-gation />
    <div v-if="flag == 1">
      <div class="box" style="padding: 5px 5px 5px 15px">
        <el-row :gutter="4" class="mt15" style="display:flex;margin-top: 6px">
          <el-col :span="3">
            <el-date-picker :clearable="false" size="small" style="width: 100%" v-model="formInline.startTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
            </el-date-picker>
          </el-col>
          <el-col :span="0.5">
            <span style="line-height: 30px; color: #dcdfe6">~</span>
          </el-col>
          <el-col :span="3">
            <el-date-picker :clearable="false" size="small" style="width: 100%" v-model="formInline.endTime" type="date" placeholder="选择日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
            </el-date-picker>
          </el-col>
          <el-col :span="3">
            <el-button type="primary"  size="small" @click="onSubmit">{{$t('public.Inquire')}}</el-button>
          </el-col>
        </el-row>
        <div class="button" style="margin-top: 10px">
          <el-button-group>
            <el-button type="primary" @click="archiveConfig()" size="small">
              {{$t('auditor.LogArchiving.ArchiveConfiguration')}}
            </el-button>
            <el-button style="border-radius: 0px 3px 3px 0px;margin: 0px 0px 0px 0px;margin-left: 1px;"  @click="delMuch(delarr)" :type="typeInfo" :disabled="!hasSeleted" size="small">删除</el-button>
          </el-button-group>
        </div>
        <div class="content">
          <el-row style="margin-top: 10px">
            <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" :row-key="getRowKeys" size="mini" stripe ref="multipleTable" :data="tableData" class="sort_table" tooltip-effect="dark" style="width: 100%; overflow: auto" @selection-change="handleSelectionChange" :default-sort="{
              prop: 'createtime',
              order: 'descending',
            }" @sort-change="sort">
              <el-table-column :reserve-selection="true" align="center" type="selection" width="60">
              </el-table-column>
              <!-- sortable="custom" -->
              <el-table-column prop="startDate" :show-overflow-tooltip="true" :label="$t('public.StartTime')" width="150">
                <template slot-scope="scope">
                  <span v-if="scope.row.time == ''"></span><span class="detaildesc" v-else>
                    {{ scope.row.startDate}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="endDate" :show-overflow-tooltip="true" :label="$t('public.EndTime')" width="150">
                <template slot-scope="scope">
                  <span v-if="scope.row.time == ''"></span><span class="detaildesc" v-else>
                    {{ scope.row.endDate }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('public.Controls')">
                <template v-slot="{ row }">
                  <span class="action_icon">
                  <img :title="$t('public.Download')" @click="resetForm(row)" src="../../images/icon_download.png" alt="" style="
                    width: 15px;
                    height: 15px;
                    cursor: pointer;
                  " />
                  </span>
                  <span class="action_icon">
                  <img :title="$t('public.Delete')" @click="deletes(row)" src="../../assets/icon_del.png" alt="" style="
                    width: 15px;
                    height: 15px;
                    cursor: pointer;
                  " />
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <pagination :page="queryData.currentPage" :limit="queryData.pageSize" :total="queryData.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
          </el-row>
        </div>
      </div>
    </div>
    <div v-if="flag == 2">
      <Configuration @archives="archives" :ruleFormData="ruleForm" />
    </div>
    <dialogInfo :dialogInfo="messageFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import naviGation from '@/components/hearder/index' //头部组件
import dialogInfo from '@/components/promptMessage/index' //提示信息
import pagination from '@/components/pagination/page.vue' //分页
import Configuration from './compontes/configuration.vue' //归档配置
export default {
  // 组件
  components: {
    pagination, //分页
    dialogInfo, //提示信息
    Configuration, //归档配置
    naviGation //头部
  },
  data() {
    return {
      typeInfo: 'info',// 删除按钮默认灰色
      flag: 1, //显示隐藏切换
      titleInfo: '', //提示信息
      visibaelFlag: false, // 关联弹框默认不展示
      messageFlag: false, //提示消息弹框默认不显示
      // 日历时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      delarr: [], //多个删除存放数组
      // 列表数据
      tableData: [],
      formInline: {
        // 选择日期
        startTime: '',
        endTime: ''
      },
      // 列表传参
      queryData: {
        //查询条件 （param）
        total: 0,
        currentPage: 1,
        pageSize: 10,
        orderColume: 'createtime',
        orderRule: 'DESC',
        input: '',
        module: '系统管理',
        level: ''
      },
      ruleForm: null
    }
  },
  computed: {
    hasSeleted() {
      return this.delarr && this.delarr.length
    }
  },
  mounted() {
    this.formInline.endTime = this.$moment().format('YYYY-MM-DD')
    this.formInline.startTime = this.$moment()
      .subtract('days', 7)
      .format('YYYY-MM-DD')
    this.dataList() //列表
    this.archiveWay()
  },
  methods: {
    getRowKeys(row) {
      return row.id
    },
    async archiveWay() {
      var param = {
        // type: this.ruleForm.archive,
        // keepTime: this.ruleForm.region,
        // enable: this.ruleForm.checked,
      }
      const res = await this.$axios.post(
        '/httpServe/archiveSetting/selectOne',
        param,
        true
      )
      this.ruleForm = res.data
      console.log(this.ruleForm, '----获取配置')
    },
    // 查询
    onSubmit() {
      this.dataList()
    },
    //列表
    async dataList() {
      if (this.formInline.startTime == null) {
        // this.titleInfo = '请选择开始时间'
        // this.messageFlag = true
        this.$message({
          message: this.$t('auditor.tableList.PleaseStartTime'),
          type: 'error',
          offset: 100
        })
      } else if (this.formInline.endTime == null) {
        // this.titleInfo = '请选择结束时间'
        // this.messageFlag = true
        this.$message({
          message: this.$t('auditor.tableList.PleaseEndTime'),
          type: 'error',
          offset: 100
        })
      } else if (this.formInline.startTime > this.formInline.endTime) {
        // this.titleInfo = '开始时间不能大于结束时间'
        // this.messageFlag = true
        this.$message({
          message: this.$t('auditor.tableList.startTimeEndTime'),
          type: 'error',
          offset: 100
        })
      } else {
        var param = {
          currentPage: this.queryData.currentPage,
          pageSize: this.queryData.pageSize,
          orderColume: this.queryData.orderColume,
          orderRule: this.queryData.orderRule,
          startDate: this.formInline.startTime,
          endDate: this.formInline.endTime
        }
        const res = await this.$axios.post(
          '/httpServe/log/archive/list',
          param,
          true
        )
        this.tableData = res.data.content
        this.queryData.total = res.data.total
      }
    },
    // 排序
    sort(column) {
      if (column.order === 'ascending') {
        this.queryData.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.queryData.orderRule = 'DESC'
      }
      this.queryData.orderColume = column.prop
      this.dataList()
    },
    //表格当删除发生变化
    handleSelectionChange(obj) {
      this.delarr = obj
      this.typeInfo = 'primary'
      if (this.delarr.length == 0) {
        this.typeInfo = 'info'
      }
    },
    //多条删除
    async delMuch(obj) {
      this.$confirm(this.$t('auditor.tableList.sureDeleteSelectedRecord'), this.$t('public.PromptMessage'), {
        cancelButtonText: this.$t('public.Cancel'),
        confirmButtonText: this.$t('public.Verify'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(async () => {
          var ids = []
          obj.forEach((item) => {
            ids.push(item.id)
          })
          var param = {
            ids: ids
          }
          const res = await this.$axios.post(
            '/httpServe/log/archive/delete',
            param,
            true
          )
          if (res.data == 1) { 
            this.$message({
              message: this.$t('public.SuccessfullyDeleted'),
              type: 'success',
              offset: 100
            })
            this.$refs.multipleTable.clearSelection()
            this.dataList()
          }
        })
        .catch(() => {
          return false
        })
    },
    deletes(val) {
      this.$confirm(this.$t('auditor.tableList.sureDeleteSelectedRecord'), this.$t('public.PromptMessage'), {
        cancelButtonText: this.$t('public.Cancel'),
        confirmButtonText: this.$t('public.Verify'),
        customClass: 'persdsd',
        type: 'warning'
      })
        .then(() => {
          this.del(val)
        })
        .catch(() => {
          return false
        })
    },
    // 删除
    async del(row) {
      var param = {
        ids: [row.id]
      }
      const res = await this.$axios.post(
        '/httpServe/log/archive/delete',
        param,
        true
      )
      console.log(res, '--删除-')
      if (res.data == 1) { 
        this.$message({
          message: this.$t('public.SuccessfullyDeleted'),
          type: 'success',
          offset: 100
        })
        this.dataList()
      }
    },
    // 弹框点击关闭事件
    handleClose() {
      this.messageFlag = false //提示消息弹框关闭
    },
    //确定的关闭
    determine() {
      this.messageFlag = false
    },
    // 分页
    handleParentGetList(page, limit) {
      this.queryData.currentPage = page
      this.queryData.pageSize = limit
      // 调用列表
      this.dataList()
    },
    archiveConfig() {
      this.flag = 2
    },
    // 导出
    async resetForm(val) {
      console.log(val, val.startDate, '导出************************')

      var startDate = val.startDate.split('-')

      var fileName = startDate[0] + '_' + startDate[1] + '_日志归档.zip'

      let token2 = sessionStorage.getItem('Authorization')
        ? sessionStorage.getItem('Authorization')
        : 'eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA'

      let userString = decodeURIComponent(
        escape(window.atob(token2.split('.')[1]))
      )
      // clientType 1管理日志 2终端日志 3告警日志
      this.userId = JSON.parse(userString).userId
      var param = {
        startDate: val.startDate,
        endDate: val.endDate,
        currentPage: this.queryData.currentPage,
        pageSize: this.queryData.pageSize,
        orderColume: this.queryData.orderColume,
        orderRule: this.queryData.orderRule
      }

      const res = await this.$axios.postExport(
        '/httpManageExport/log/archive/export',
        param,
        false,
        this.userId
      )
      const link = document.createElement('a')
      console.log(1111111)
      // var fileName = 'exportLogArchive_' + Date.parse(new Date()) + '.xls'
      let blob = new Blob([res.data], { type: 'application/zip' })
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', decodeURI(fileName))
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    // 归档配置
    archives(all) {
      console.log(all, 'all归档配置')
      if (all == 1) {
        this.dataList()
        this.archiveWay()
        // this.messageFlag = true
        // this.titleInfo = '保存成功'
        this.$message({
          message: this.$t('public.SuccessfullySaved') ,
          type: 'success',
          offset: 100
        })
        this.flag = 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
