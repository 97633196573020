<template>
  <div>
    <div style="width: 700px;margin-top:25px;margin-left:60px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('auditor.LogArchiving.ADDList.ArchivingMethod')">
          <el-select  size="small"  v-model="ruleForm.archive" >
            <el-option :label="$t('auditor.LogArchiving.ADDList.LocalArchive')" value="0"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.DirectDiscard')" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('auditor.LogArchiving.ADDList.RetentionTime')">
          <el-select  size="small"  v-model="ruleForm.region" >
            <el-option :label="$t('auditor.LogArchiving.ADDList.oneMonth')" value="1"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.twoMonth')" value="2"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.threeMonth')" value="3"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.fourMonth')" value="4"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.fiveMonth')" value="5"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.sixMonth')" value="6"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.sevenMonth')" value="7"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.eightMonth')" value="8"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.nineMonth')" value="9"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.tenMonth')" value="10"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.elevenMonth')" value="11"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.twelveMonth')" value="12"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.thirteenMonth')" value="13"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.fourteenMonth')" value="14"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.fifteenMonth')" value="15"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.sixteenMonth')" value="16"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.seventeenMonth')" value="17"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.eighteenMonth')" value="18"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.nineteenMonth')" value="19"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.twentyMonth')" value="20"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.twentyOneMonth')" value="21"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.twentyTwoMonth')" value="22"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.twentyThereMonth')" value="23"></el-option>
            <el-option :label="$t('auditor.LogArchiving.ADDList.twentyFourMonth')" value="24"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('auditor.LogArchiving.ADDList.Activate')">
          <el-checkbox
            true-label="1"
            false-label="0"
            v-model="ruleForm.checked"
          ></el-checkbox>
        </el-form-item>
        <el-form-item>
          <el-button  size="small"  type="primary" @click="submitForm('ruleForm')"
            >{{$t('public.Save')}} </el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- 提示消息框 -->
    <dialogInfo
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import dialogInfo from "@/components/promptMessage/index"; //提示消息框
export default {
  props: {
    ruleFormData: {
      typeof: Object,
      default: () => {},
    },
  },
  components: {  
    dialogInfo,//提示框信息
  },
  data() {
    return {
      titleInfo:"",
      visibaelFlag:false,//提示框信息默认不展示
      ruleForm: {
        archive: "", //归档方式
        region: "",
        checked: "", //启用选中
      },
      rules: {
        // region: [
        //   { required: true, message: "请选择活动区域", trigger: "change" },
        // ],
      },
    };
  },

  created() {
    if (this.ruleFormData) {
      this.ruleForm.archive = this.ruleFormData.type + "";
      this.ruleForm.region = this.ruleFormData.keepTime + "";
      this.ruleForm.checked = this.ruleFormData.enable + "";
    }
  },
  methods: {
    async save() {
      var param = {
        type: this.ruleForm.archive,
        keepTime: this.ruleForm.region,
        enable: this.ruleForm.checked,
      };
      const res = await this.$axios.post(
        "/httpServe/archiveSetting/update",
        param,
        true
      );
      if(res.data==1){
        this.$emit("archives", 1); 
      } 
       
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save();
        } else {
        //   console.log("error submit!!");
          return false;
        }
      });
    },
    // 弹框点击关闭事件
    handleClose() { 
      this.visibaelFlag = false; //提示消息弹框关闭
    },
    //确定的关闭
    determine() {
      this.visibaelFlag = false;//提示确定弹框关闭
    },
  },
};
</script>

<style lang="scss" scoped>

</style> 